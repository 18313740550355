<template>
  <div>
    <b-overlay :show="isShowLoader" no-wrap style="z-index: 20000"></b-overlay>
    <b-container fluid class="mainContainer">
      <b-row style="height: 100vh">
        <b-col col lg="2" class="leftPanelStyle"><b-row class="ownerName">
            <b-col col lg="3">
              <b-img :src="avatorImg" rounded="circle" class="profileImage"></b-img>
            </b-col>
            <b-col col lg="9" style="padding:right: 0px;">
              <p class="profileNameList">
                {{ $store.state.loginUserName }}
                <!-- Not defined -->
              </p>
            </b-col>
          </b-row>
          <!-- ---------------------------------- System message ---------------------------------- -->
          <b-row style="padding: 6px 0px; height: 55px; cursor: pointer" @click="onRecentUser('system')">
            <b-col col lg="3" style="padding-right: 0px">
              <b-avatar
                src="https://wycy.oss-ap-southeast-1.aliyuncs.com/chat-space/bd09fce4796f4663a6cd589aff540d10.jpg"></b-avatar>
            </b-col>

            <b-col col lg="6" style="padding-right: 0px">
              <p class="profileName" style="padding-top: 10px">系统消息</p>
            </b-col>
            <b-col col lg="3">
              <div class="messageCount">
                <b-badge variant="danger" style="border-radius: 25px" v-if="$store.state.system_unread">{{
                  $store.state.system_unread }}</b-badge>
              </div>
            </b-col>
          </b-row>
          <!------------------------------------ Agent list with message count ---------------------------------- -->

          <div v-if="agentListPannel" class="container1">
            <b-row v-for="(i, index) in $store.state.message_list" :key="index" style="padding: 6px 0px; cursor: pointer"
              @click="onRecentUser(i)">
              <b-col collg="3" style="padding-right: 0px">
                <!-- <b-avatar
                  badge
                  badge-top
                  badge-variant="success"
                  v-bind:src="i.user.avatar"
                ></b-avatar> -->
                <b-avatar :src='getAvatar(i, false)'></b-avatar>
              </b-col>

              <b-col col lg="6">
                <p class="profileName" style="padding-top: 10px">
                  <!-- {{ !i.name ? i.account : i.name }} -->
                  {{ getActiveName(i) }}
                </p>
              </b-col>
              <b-col col lg="3">
                <div class="messageCount">
                  <!-- <b-badge
                    variant="danger"
                    style="border-radius: 25px"
                    v-if="i.unread > 0"
                    >{{ i.unread }}</b-badge
                  > -->
                  <b-badge variant="danger" style="border-radius: 25px" v-if="i.count > 0">{{ i.count }}</b-badge>
                  <!-- <div v-for="(test,index) in i" :key="index">
                      <b-badge variant="danger" style="border-radius: 25px;display: none;">{{ test }}</b-badge>
                  </div> -->
                  <!-- <b-badge variant="danger" style="border-radius: 25px" v-if="getCustomersMSgCount(i) > 0" >{{ getCustomersMSgCount(i) }}</b-badge> -->
                </div>
              </b-col>
            </b-row>
            <!------------------------------------ Bottom tabs (Group and Info) ---------------------------------- -->
          </div>
          <!------------------------------------ Channel list with message count ---------------------------------- -->

          <div v-if="channelListPannel" class="container1">
            <b-row v-if="$store.state.channel_list.length === 0">
              <p>--- No channels ---</p>
            </b-row>
            <b-row v-else v-for="i in $store.state.channel_list" :key="i.id" @click="onRecentChannel(i)"
              style="padding: 6px 0px; cursor: pointer">
              <b-col col lg="3" style="padding-right: 0px">
                <b-avatar v-bind:src="i.user.avatar"></b-avatar>
              </b-col>

              <b-col col lg="6">
                <p class="profileName" style="padding-top: 10px">
                  <!-- {{ i.user.name }} -->
                </p>
              </b-col>
              <b-col col lg="3">
                <div class="messageCount">
                  <b-badge variant="danger" style="border-radius: 25px" v-if="i.unread > 0">{{ i.unread }}</b-badge>
                </div>
              </b-col>
            </b-row>
            <!------------------------------------ Bottom tabs (Group and Info) ---------------------------------- -->
          </div>
          <b-row class="bottomBar">
            <!-- ---------------------------------- Search User and channel ---------------------------------- -->
            <b-col col class="bottomContent" v-show="agentListPannel">
              <p class="groupName" v-b-toggle.sidebar-footer_search @click="onSearchButton">
                <b-icon icon="search" aria-hidden="true"></b-icon>
              </p>
            </b-col>
            <b-col col class="bottomContent" v-show="channelListPannel">
              <p class="groupName" v-b-toggle.sidebar-footer_searchChannel @click="onSearchButtonChannel">
                <b-icon icon="search" aria-hidden="true"></b-icon>
              </p>
            </b-col>
            <!-- ---------------------------------- App info ----------------------------------
            <b-col col class="bottomContent">
              <p
                class="groupName"
                @click="$bvModal.show('modal-scoped_appInfo')"
              >
                <b-icon icon="info-circle" aria-hidden="true"></b-icon>
              </p>
            </b-col> -->
            <!-- ---------------------------------- Agents ---------------------------------- -->
            <b-col col class="bottomContent">
              <p class="groupName" @click="showAgentList()">
                <b-icon icon="person" aria-hidden="true"></b-icon>
              </p>
            </b-col>
            <!-- ---------------------------------- Group ---------------------------------- -->
            <b-col col class="bottomContent">
              <p class="groupName" v-b-toggle.sidebar-footer_group>
                <b-icon icon="chat-text" aria-hidden="true"></b-icon>
              </p>
            </b-col>

            <!-- ---------------------------------- Channel ---------------------------------- -->
            <!-- <b-col col>
              <p class="groupName" @click="showChannelList()">
                <b-icon icon="people" aria-hidden="true"></b-icon>
              </p>
            </b-col> -->

            <!-- ======= Group Section - Componet name "Groups" ======-->
            <groupsList></groupsList>
            <!-- ======= Search user sidebar Section - Componet name "Searchuser" ====== -->
            <searchUser></searchUser>
            <!-- ======= Search user sidebar Section - Componet name "Searchuser" ====== -->
            <!-- <channelSearch></channelSearch> -->
            <!-- ============== App Info Modal - Componet name "AppInfo"================ -->
            <appInfo></appInfo>
          </b-row>
        </b-col>

        <!-- =========================================== Right pannel ================================================ -->
        <b-col col lg="10" style="background: #efefef; height: 100%">
          <b-row style="background: #435f7a; height: 50px">
            <b-col col lg="6">
              <p class="chatUsername">
                {{
                  getActiveName(selectedUser)
                }}
              </p>
              <!-- <p v-if="channelButtonGroup" class="chatUsername">
                {{ selectedChannel.channelname }}
              </p> -->
            </b-col>
            <!-- ======================================================================================================== -->
            <!-- -------------------------------------- Agent button group--------------------------------------------- -->
            <!-- ======================================================================================================== -->
            <b-col col lg="6" v-if="agentButtonGroup" style="padding-top: 5px">
              <b-button-toolbar style="float: right">
                <b-button-group class="mr-1">
                  <!-- ---------------------------------- Transfer agent icon ---------------------------------- -->
                  <b-button v-b-modal.modal-scrollable_trans style="background: #2c3e50" v-if="selectedUser.id != null">
                    <b-icon icon="person-check" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- ====== Transfer agent Modal  - Componet name "AgentTransfer"======= -->
                  <agentTransfer v-bind:selectedUser="selectedUser"></agentTransfer>
                  <!-- ---------------------------------- Agent Details icon ---------------------------------- -->
                  <b-button v-b-modal.modal-scoped_agentdetail style="background: #2c3e50" v-if="selectedUser.id != null">
                    <b-icon icon="info-circle" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                  </b-button>
                  <!-- ========= Agent Details Modal - Componet name "AgentsInfo" ======== -->
                  <agentsInfo v-bind:selectedUser="selectedUser"></agentsInfo>
                  <!-- ----------------------------------Question list icon ---------------------------------- -->

                  <b-button v-b-modal.modal-scrollable_question style="background: #2c3e50"
                    v-if="selectedUser.id != null">
                    <b-icon icon="question-circle" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- ====== Question List Modal - Componet name "QuestionList" ========= -->
                  <questionsList v-bind:parentContext="this" @onQuickReplySelected="updateMessage"> </questionsList>
                  <!-- ---------------------------------- History icon ---------------------------------- -->
                  <b-button v-b-modal.modal-scrollable_history style="background: #2c3e50" v-if="selectedUser.id != null"
                    @click="getHistoryMsg">
                    <b-icon icon="clock-history" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                  </b-button>
                  <!-- ======= History message Modal - Componet name "History" ======= -->
                  <history ref="getHistoryMsg" v-bind:selectedUser="selectedUser"></history>

                  <!-- ---------------------------------- Logout icon ---------------------------------- -->
                  <b-button style="background: #2c3e50" @click="logout">
                    <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
              <!-- <b-alert v-model="emptyMessageError" variant="danger" dismissible>
                No message to send!
              </b-alert> -->
            </b-col>
            <!-- ======================================================================================================== -->
            <!-- -------------------------------------- Channel button group--------------------------------------------- -->
            <!-- ======================================================================================================== -->
            <b-col col lg="6" style="padding-top: 5px">
              <b-button-toolbar style="float: right">
                <b-button-group v-if="channelButtonGroup" class="mr-1">
                  <!-- ---------------------------------- Channel member list and permission ---------------------------------- -->
                  <b-button v-b-modal.modal-scoped_channelUserPermission @click="showChannelMembers()"
                    style="background: #2c3e50" v-if="selectedUser.id != null">
                    <b-icon icon="list-ul" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- ====== Channel member list and permission Modal  - Componet name ""======= -->
                  <!-- <channelPermission ref="showChannelMembers" v-bind:selectedUser="selectedUser"></channelPermission> -->
                  <!-- ---------------------------------- Channel add & remove user---------------------------------- -->
                  <b-button v-b-modal.modal-scoped_channelAddRemove @click="showMembersList()" style="background: #2c3e50"
                    v-if="selectedUser.id != null">
                    <b-icon icon="pencil-square" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                  </b-button>
                  <!-- ========= Channel add & remove user Modal - Componet name "" ======== -->
                  <!-- <channelAddRemoveUser ref="showMembersList" v-bind:selectedUser="selectedUser"></channelAddRemoveUser> -->

                  <!-- ----------------------------------Channel search and add user ---------------------------------- -->
                  <b-button v-b-modal.modal-scoped_channelAddSearch style="background: #2c3e50"
                    v-if="selectedUser.id != null">
                    <b-icon icon="person-plus" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- ====== Channel search and add useruestion List Modal - Componet name "" ========= -->
                  <!-- <channelSearchAddUser v-bind:selectedUser="selectedUser">
                  </channelSearchAddUser> -->
                  <!-- ----------------------------------Channel History ---------------------------------- -->
                  <b-button v-b-modal.modal-scrollable_historyChannel @click="getHistoryChannel"
                    style="background: #2c3e50" v-if="selectedUser.id != null">
                    <b-icon icon="clock-history" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                  </b-button>
                  <!-- ======= Channel History Modal - Componet name "" ======= -->
                  <!-- <channelHistory ref="getHistoryChannel" v-bind:selectedUser="selectedUser"></channelHistory> -->

                  <!-- ---------------------------------- Logout icon ---------------------------------- -->
                  <b-button style="background: #2c3e50" @click="logout">
                    <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
              <!-- <b-alert v-model="emptyMessageError" variant="danger" dismissible>
                No message to send!
              </b-alert> -->
            </b-col>
          </b-row>
          <b-row class="chatWindowBody">
            <ul id="chatWindowBodyUl">
              <!-- ---------------------------------- Recieve message UI ------------------------------------>
              <li v-for="(message, id) in selectedMessageList" :key="id">
                <!-- Agent Transfer Message -->
                <b-row v-if="message.type == -1">
                  <b-col cols="4">

                  </b-col>
                  <b-col cols="4">
                    <div
                      style="background-color: #b0a7a7; border-radius: 4px; color: white; font-weight: bold; margin: 10px;">
                      {{ message.message }}
                    </div>
                  </b-col>
                  <b-col cols="4">

                  </b-col>
                </b-row>
                <!-- --- -->
                <!-- For normal messages -->
                <div v-if="message.type != -1 && message.status == 1">
                  <div v-bind:class="[
                    message.sender == selectedUser.number ? 'receive' : 'sent',
                  ]">
                    <!-- Message Resend -->
                    <!-- <b-icon icon="arrow-clockwise" class="rounded-circle bg-danger" variant="light" font-scale="2"
                      v-if="message.sendfail" @click="resendFailMessage(message)" style="cursor: pointer"></b-icon>
                    <b-icon v-if="message.sending" icon="clock" class="rounded-circle" font-scale="1"></b-icon> -->
                    <!-- <b-icon v-if="!message.sending &&
                      message.sender != selectedUser.id &&
                      !message.sendfail
                      " icon="check" class="rounded-circle" font-scale="1"></b-icon> -->
                    <!-- Message Bubble -->
                    <img style="margin: 5px;" :src="selectedUser.id == null ? systemImage : getAvatar(selectedUser, message.sender != selectedUser.id)" class="imageleft"
                      v-bind:class="[
                        message.sender === selectedUser.id
                          ? 'imageleft'
                          : 'imageright',
                      ]" />
                    <div :style="`text-align: ${message.sender == selectedUser.number ? 'start' : 'unset'};`">
                      
                      <span v-if="message.sender == selectedUser.number" class="userDetailLine" style="margin-left: 5px;">
                      {{ getActiveName(selectedUser) }}</span><br />

                      <div class="talktext" @contextmenu.prevent="openContext($event, `menu_${message.id}`, message.id, message.sender == selectedUser.number)">
                        <p v-if="message.type == 0" v-html="processContent(message.message)" class="textStyle"></p>
                        <b-img v-if="message.type == 1" :src="message.message" @click="showImg(message.message)"
                          style="width: auto; height: 200px; max-width: 600px"></b-img>

                        <!-- ================================= voice record messages ========================= -->
                        <audio controls v-if="message.type == 2" :src="message.message" type="audio/mpeg"></audio>


                        <b-embed v-if="message.type == 8" type="video" aspect="4by3" controls>
                          <source :src="message.message
                            " type="video/mp4" />
                        </b-embed>
                        <!-- ================================= File ========================= -->
                        <!-- def.png == doc.png == gif.png == html.png == jpeg.png == mp3.png == png.png == ppt.png == rar.png == txt.png  == xls.png == zip.png -->
                        <div v-if="message.type == 3" @click="onFile(message.message)" class="receiveFileStyle">
                          <img v-bind:src="getFileType(message.message)" style="width: auto; height: 100px" />
                          <p class="fileName" style="color: #ced4da; font-size: 13px">
                            <!-- {{ JSON.parse(message.content).name }} -->
                          </p>
                        </div>
                        <p v-if="channelButtonGroup && message.action == 3"
                          style="text-align: right; color: #ced4da; font-size: 13px">
                          {{ getMessageSenderInformation(message.extra) }}
                        </p>
                        <div>
                          <b-row>
                            <b-col :cols="message.sender === selectedUser.id ? '12': '10'">
                              <p style="text-align: right; color: #ced4da; font-size: 13px">
                                {{ getTime(message.time) }}
                              </p>
                            </b-col>
                            <b-col cols="2">
                              <div>
                                <b-icon icon="check2" style="color: #ced4da;" v-if="getMessageStatus(message) == 0"></b-icon>
                                <b-icon icon="check2-all" style="color: #ced4da;" v-if="getMessageStatus(message) == 2"></b-icon>
                                <b-icon icon="check2-all" style="color: #31ff00;" v-if="getMessageStatus(message) == 1"></b-icon>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                        
                      </div>

                      <vueContex :ref="`menu_${message.id}`">
                          <div class="delete-btn" @click="deleteMsg(message.order_id, `menu_${message.id}`)">
                            撤回
                          </div>
                      </vueContex>

                    <!-- ================================= voice record messages ========================= -->
                    <!-- <audio controls v-if="message.type == 2" :src="message.message" type="audio/mpeg"></audio>


                    <b-embed v-if="message.type == 8" type="video" aspect="4by3" controls>
                      <source :src="message.message
                        " type="video/mp4" />
                    </b-embed> -->
                    <!-- ================================= File ========================= -->
                    <!-- def.png == doc.png == gif.png == html.png == jpeg.png == mp3.png == png.png == ppt.png == rar.png == txt.png  == xls.png == zip.png -->
                    <!-- <div v-if="message.type == 3" @click="onFile(message.message)" class="receiveFileStyle">
                      <img v-bind:src="getFileType(message.message)" style="width: auto; height: 100px" />
                      <p class="fileName" style="color: #ced4da; font-size: 13px">
                      </p> -->
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div id="bottomChat"></div>
          </b-row>

          <!-- ---------------------------------- message input ---------------------------------- -->
          <b-row class="message_input" v-if="selectedUser.id">
            <b-input-group size="sm" style="padding: 0px">
              <!-- ======== emoji icon ========== -->
              <b-input-group-prepend id="showPopoverEmoji">
                <b-input-group-text style="font-size: 20px; cursor: pointer">
                  <b-icon icon="emoji-smile"></b-icon>
                </b-input-group-text>
              </b-input-group-prepend>
              <!-- ========= emoji popup ========= -->
              <b-popover target="showPopoverEmoji" placement="topright" :show.sync="emojiPopupShow">
                <ul class="emojiDesign">
                  <li class="emojiListDesign" v-for="(item, index) in emojiIcons" :key="index" @click="onEmoji(item)">
                    <b-img :src="require(`../assets/img/emoji_files/${item.id}.png`)" />
                  </li>
                </ul>
              </b-popover>
              <!-- ----------------------------------input area ---------------------------------- -->
              <!-- <b-form-input type="text"></b-form-input> -->
              <b-form-textarea v-model="inputMessage" id="textarea-small" size="sm"
                v-on:keyup.enter.exact="sendMessage(0)" v-on:keyup.shift.enter="inputMessage + '\r\n'"
                @paste="onPaste"></b-form-textarea>
              <!-- =======file attachment icon ======= -->
              <b-input-group-append>
                <b-input-group-text id="showAttachments" style="font-size: 20px; cursor: pointer"><b-icon
                    icon="paperclip"></b-icon>
                </b-input-group-text>
                <!-- ========Attachment popup ======= -->
                <b-popover target="showAttachments" triggers="click" placement="topleft" :show.sync="popoverShow">
                  <b-button-toolbar style="padding: 10px">
                    <b-button-group>
                      <b-button title="Upload Image">
                        <!-- <input
                          id="fileUploadImage"
                          ref="fileUploadImage"
                          type="file"
                          hidden
                          @change="onImageUpload"
                          accept="image/*"
                        /> -->
                        <b-icon icon="card-image" accept="image/*" @click="chooseImage()" aria-hidden="true"></b-icon>
                      </b-button>
                      <!-- <input
                        id="fileUploadFile"
                        type="file"
                        accept="file/*"
                        hidden
                        @change="onFileUpload"
                      /> -->
                      <b-button title="Uploag file">
                        <b-icon icon="folder" aria-hidden="true" @click="chooseFile()"></b-icon>
                      </b-button>
                      <input id="fileUploadVideo" type="file" accept="video/*" hidden @change="onVideoUpload" />
                      <b-button title="Upload video">
                        <b-icon icon="camera-video" aria-hidden="true" @click="chooseVideo()"></b-icon>
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </b-popover>
                <!-- =========send message icon ========== -->
                <b-input-group-text @click="sendMessage(0)" style="font-size: 20px; cursor: pointer">
                  <b-icon icon="chevron-right"></b-icon>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
// import "viewerjs/dist/viewer.css";
// import Viewer from "v-viewer";
import Vue from "vue";
import moment from "moment";
import CimSdk from "../assets/cim/cim.web.sdk";
import "../assets/cim/message";
import "../assets/cim/replybody";
import "../assets/cim/sentbody";
import $ from "jquery";
import QuestionList from "./QuestionList";
import Groups from "./Groups";
import History from "./History.vue";
import AgentTransfer from "./AgentTransfer";
import AgentsInfo from "./AgentsInfo.vue";
import SearchUser from "./SearchUser";
// import ChannelSearch from "./ChannelSearch";
import AppInfo from "./AppInfo";
import hevueImgPreview from "hevue-img-preview";
Vue.use(hevueImgPreview);
import eventBus from "../assets/js/eventBus";
// import ChannelPermission from "./ChannelPermission";
// import ChannelAddRemoveUser from "./ChannelAddRemoveUser";
// import ChannelSearchAddUser from "./ChannelSearchAddUser";
// import ChannelHistory from "./ChannelHistory";
import noticeSound from "../assets/sound/notice.mp3";
import axios from 'axios'
import VueContext from 'vue-context';
import 'vue-context/dist/css/vue-context.css';
// Vue.use(Viewer);
export default {
  name: "ChatWindow",
  components: {
    questionsList: QuestionList,
    groupsList: Groups,
    history: History,
    agentTransfer: AgentTransfer,
    searchUser: SearchUser,
    // channelSearch: ChannelSearch,
    agentsInfo: AgentsInfo,
    appInfo: AppInfo,
    vueContex: VueContext
    // channelPermission: ChannelPermission,
    // channelAddRemoveUser: ChannelAddRemoveUser,
    // channelSearchAddUser: ChannelSearchAddUser,
    // channelHistory: ChannelHistory,
  },
  data() {
    return {
      showPopoverEmoji: false,
      file1: null,
      selectedImage: null,
      emojiIcons: [
        {
          id: 0,
          text: "../assets/img/emoji_files/0.png",
          key: "[微笑]",
        },
        {
          id: 1,
          text: this.$baseURL + "../assets/img/emoji_files/1.png",
          key: "[爱你]",
        },
        {
          id: 2,
          text: this.$baseURL + "../assets/img/emoji_files/2.png",
          key: "[拜拜]",
        },
        {
          id: 3,
          text: this.$baseURL + "../assets/img/emoji_files/3.png",
          key: "[悲伤]",
        },
        {
          id: 4,
          text: this.$baseURL + "../assets/img/emoji_files/4.png",
          key: "[鄙视]",
        },
        {
          id: 5,
          text: this.$baseURL + "../assets/img/emoji_files/5.png",
          key: "[闭嘴]",
        },
        {
          id: 6,
          text: this.$baseURL + "../assets/img/emoji_files/6.png",
          key: "[馋嘴]",
        },
        {
          id: 7,
          text: this.$baseURL + "../assets/img/emoji_files/7.png",
          key: "[吃惊]",
        },
        {
          id: 8,
          text: this.$baseURL + "../assets/img/emoji_files/8.png",
          key: "[鼓掌]",
        },
        {
          id: 9,
          text: this.$baseURL + "../assets/img/emoji_files/9.png",
          key: "[哈哈]",
        },
        {
          id: 10,
          text: this.$baseURL + "../assets/img/emoji_files/10.png",
          key: "[害羞]",
        },
        {
          id: 11,
          text: this.$baseURL + "../assets/img/emoji_files/11.png",
          key: "[黑线]",
        },
        {
          id: 12,
          text: this.$baseURL + "../assets/img/emoji_files/12.png",
          key: "[可爱]",
        },
        {
          id: 13,
          text: this.$baseURL + "../assets/img/emoji_files/13.png",
          key: "[可怜]",
        },
        {
          id: 14,
          text: this.$baseURL + "../assets/img/emoji_files/14.png",
          key: "[亲亲]",
        },
        {
          id: 15,
          text: this.$baseURL + "../assets/img/emoji_files/15.png",
          key: "[生病]",
        },
        {
          id: 16,
          text: this.$baseURL + "../assets/img/emoji_files/16.png",
          key: "[偷笑]",
        },
        {
          id: 17,
          text: this.$baseURL + "../assets/img/emoji_files/17.png",
          key: "[挖鼻]",
        },
        {
          id: 18,
          text: this.$baseURL + "../assets/img/emoji_files/18.png",
          key: "[委屈]",
        },
      ],
      sendImage: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx1e3Brs1-mXteyLcxqZxVXQQhMQLKUcnLxA&usqp=CAU",
      ],
      selectedUser: { username: "" },
      selectedMessageList: [],
      inputMessage: "",
      emptyMessageError: false,
      isShowLoader: false,
      popoverShow: false,
      emojiPopupShow: false,
      agentListPannel: true,
      channelListPannel: false,
      agentButtonGroup: true,
      channelButtonGroup: false,
      avatorImg: require("../assets/img/chat_files/avator.png"),
      systemImage: 'https://wycy.oss-ap-southeast-1.aliyuncs.com/chat-space/bd09fce4796f4663a6cd589aff540d10.jpg',
      userAvatarImg: "https://wycy.oss-ap-southeast-1.aliyuncs.com/chat-space/f6625c68fa7e45adbcf7c8a91d33ec17.png",
      msgStatuses: new Map(),
      msgStatusTimer: {},
      showDelete: false,
      openedContextId: '',
      testStoreMsgs: this.$store.state.message_list
      //======== Channel
      // allChannelList: "",
      // selectedChannel: { channelname: "", channelid: "" },
    };
  },
  computed: {},
  methods: {
    openContext(event, menu, message, isReceived) {
      if (!isReceived) {
        this.$refs[menu][0].open(event, message)
        if (this.openedContextId !== '') {
          this.$refs[this.openedContextId][0].close()
        }
        this.openedContextId = menu;
      }
    },
    chooseImage: function () {
      const inputElement = document.createElement('input');

      inputElement.type = 'file';
      inputElement.accept = 'image/png, image/gif, image/jpeg'
      inputElement.addEventListener('change', () => {
        if (inputElement.files.length > 0) {
          this.uploadFile(inputElement.files[0], 1);
        }
      });
      inputElement.click();
      this.onClose();

    },
    chooseVideo: function () {
      // document.getElementById("fileUploadVideo").click();
      // this.onClose();
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = 'video/mp4,video/x-m4v,video/*';
      inputElement.addEventListener('change', () => {
        if (inputElement.files.length > 0) {
          this.uploadFile(inputElement.files[0], 8);
        }
      });
      inputElement.click();
      this.onClose();
    },
    updateMessage(msg) {
      this.inputMessage = msg;
    },
    chooseFile: function () {
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.addEventListener('change', () => {
        if (inputElement.files.length > 0) {
          this.uploadFile(inputElement.files[0], 3);
        }
      });
      inputElement.click();
      this.onClose();
    },

    showImg(url) {
      this.$hevueImgPreview(url);
    },

    onCloseEmojiPop() {
      this.emojiPopupShow = false;
    },

    onClose() {
      this.popoverShow = false;
    },

    /**
     * check if the there is login user. if not move to login
     */
    // isLogin() {
    //   if (!this.$store.state.login_account) {
    //     this.$router.push({ name: "Login" });
    //   } else {
    //     this.startChatSystem();
    //     this.loadOnlineUsers();
    //   }
    // },
    /**
     * logout current user without clear cache
     */
    logout() {
      // localStorage.clear();
      this.$store.commit("clearAllData");
      this.$store.commit("clearAllSystemMessages");
      this.$router.push({ name: "Login" });
      window.location.reload();
    },
    /**
     * start the chat socket
     */
    startChatSystem() {
      CimSdk.connect(this);
    },
    /**
     * no implementaion yet
     */
    onReplyReceived(reply) {
      localStorage.setItem("lastHeartBeat", reply.timestamp);
      if (reply.key === "client_bind") {
        if (reply.code === "200") {
          CimSdk.startHeartBeat();
        }
      } else if (reply.key === "heartbeat") {
        //here comes heartbeat
      }
    },
    /**
     * this method gets invoked when new message comes from socket
     * and this will pass that information to the store
     */
    onMessageReceived(message) {
      // console.log(message);
      this.$store.commit("setMessage", {
        message: message,
        caller: this,
        sentMessage: false,
      });
      this.receiveRequest(message.id);
      //if chat already open, set the read status
      if (
        this.selectedUser.account === message.sender &&
        this.agentButtonGroup
      ) {
        this.$store.commit("readAllUnread", {
          account: this.selectedUser.account,
          context: this,
        });
      }

      if (this.selectedUser.id == message.sender && this.channelButtonGroup) {
        this.$store.commit("readAllUnreadChannel", {
          account: this.selectedUser.id,
          context: this,
        });
      }
      // setTimeout(() => {
      $(".chatWindowBody").animate(
        { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
        500
      );
      // }, 500);
    },
    /**
     * method to handle click on user from the left menu
     * set selected user
     * populate selected users' messages
     * set unread messages as read
     */
    onRecentUser(item) {
      //show agent button group in the top
      this.agentButtonGroup = true;
      this.channelButtonGroup = false;
      this.openedContextId = ''

      if (item === "system") {

        //load system messages
        this.selectedUser = { id: null, username: "系统消息", sender: 'SYSTEM', number: 'SYSTEM' };
        this.selectedMessageList = this.$store.state.system_messages;
        //Clear unread count
        this.$store.commit('updateSystemMessageCount', true)
        // this.loadSystemMessages();
      } else {

        this.selectedUser = item;

        this.getUserInfo(this.selectedUser.number);
        //set unread count to 0;
        item.unread = 0;
        const unreadIndex = this.$store.state.message_list.findIndex(agent => agent.number == item.number);
        this.$store.commit('updateUnreadCount', {
          index: unreadIndex,
          // count: 0
          reset: true
        })
        //set unread messages read
        // this.$store.commit("readAllUnread", {
        //   account: this.selectedUser.account,
        //   context: this,
        // });
        // this.$store.commit("updateMessageItem", item);
      }
      setTimeout(() => {
        $(".chatWindowBody").animate(
          { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
          500
        );
      }, 500);
      if (item != "system") {
        this.selectedMessageList = item.agentMessageList
        this.getReadMsgs(item.number)
      }

      // Clear the existing timer and start the new timer for status checking
      clearInterval(this.msgStatusTimer)
      this.checkStatusOfMsgs()
      this.msgStatusTimer = setInterval(() => {
        this.checkStatusOfMsgs()
      }, 5000)
    },
    /**
     * api to mark messages as read
     */
    getUserInfo(userId) {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/user_info`,
          method: "post",
          headers: {

          },
          data: {
            id: userId
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            that.selectedUser = { ...that.selectedUser, telephone: res.data.data.telephone_number }
            // that.selectedUser.telephone = res.data.data.telephone_number;
          } else if (res.data.status == 401) {
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          }
        }).catch((e) => {
          console.error(e.message);
        });
    },
    readRequest(id, account) {
      let that = this;
      that
        .$http({
          url: "api/message/read/" + id + "/" + account,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          // if (data.code == 200) {
          //   that.$store.commit("updateUser", data.data);
          // }
        }).catch((e) => {
          console.log(e.message);
        });
    },
    receiveRequest(id) {
      let that = this;
      that
        .$http({
          url: "/api/message/received/" + id,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => { });
    },
    /**
     * convert timestamp to human readable date time
     */
    getTime(timestamp) {
      return moment(timestamp).format("MMMM Do YYYY, h:mm:ss a");
    },
    getFileType(content) {
      let fileNameArray = content.split(".");
      let fileType = fileNameArray[fileNameArray.length - 1];
      switch (fileType) {
        case "pdf":
          return require("../assets/img/chat_files/pdf.png");
        case "doc":
          return require("../assets/img/chat_files/doc.png");
        case "docx":
          return require("../assets/img/chat_files/doc.png");
        case "ppt":
          return require("../assets/img/chat_files/ppt.png");
        case "xls":
          return require("../assets/img/chat_files/xls.png");
        case "xlsx":
          return require("../assets/img/chat_files/xls.png");
        case "jpeg":
          return require("../assets/img/chat_files/jpeg.png");
        case "jpg":
          return require("../assets/img/chat_files/jpeg.png");
        case "mp3":
          return require("../assets/img/chat_files/mp3.png");
        case "png":
          return require("../assets/img/chat_files/png.png");
        case "rar":
          return require("../assets/img/chat_files/rar.png");
        case "txt":
          return require("../assets/img/chat_files/txt.png");
        case "zip":
          return require("../assets/img/chat_files/zip.png");
        case "gif":
          return require("../assets/img/chat_files/gif.png");
        default:
          return require("../assets/img/chat_files/def.png");
      }
    },

    /**
     * get user information on given account
     * and update the user information for that account in the store
     */
    // getUserInfo(account) {
    //   // console.log('get user info',user);
    //   let that = this;
    //   that
    //     .$http({
    //       url: "api/user/userdetails/" + account,
    //       method: "get",
    //       headers: {
    //         "access-token": that.$store.state.token,
    //       },
    //       data: {},
    //     })
    //     .then((res) => {
    //       let data = res.data;
    //       if (data.code == 200) {
    //         that.$store.commit("updateUser", data.data);
    //       }
    //     })
    //     .catch(() => {
    //       let data = {
    //         phone: "",
    //         user_name: account,
    //         organization: "大牛证券配资系统",
    //         avatar:
    //           "https://wycy.oss-ap-southeast-1.aliyuncs.com/chat-space/f6625c68fa7e45adbcf7c8a91d33ec17.png",
    //         account: account,
    //       };
    //       that.$store.commit("updateUser", data);
    //     });
    // },

    /**
     * get channel information on given account
     * and update the channel information for that account in the store
     */
    getChannelInfo(account) {
      // console.log('get user info',user);
      let that = this;
      that
        .$http({
          url: "api/group/" + account + "?noUserData=1&type=1",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.$store.commit("updateChannel", data.data);
          }
        });
    },
    /**
     * show the notice
     */
    makeToast(message, account, titleText) {
      var content;
      switch (message.format) {
        case "0":
          content = this.processContent(message.content);
          break;
        case "1":
          content = "图片";
          break;
        case "8":
          content = "视频";
          break;

        default:
          break;
      }
      this.$vToastify.success({
        title: titleText ? titleText : "新信息",
        body: content,
        type: "success",
        canTimeout: true,
        duration: 5000,
        position: "top-right",
      });
    },
    sendMessage(format = 0, content = null) {
      console.log("Selected user ID: ", this.selectedUser)
      if (format == 0) {
        this.sendMessageApi(this.inputMessage, format, this.selectedUser.number);
      } else {
        this.sendMessageApi(content, format, this.selectedUser.number);
      }

      // switch (format) {
      //   //Text message
      //   case 0:
      //     content = this.inputMessage;
      //     this.sendMessageApi(content, format, this.selectedUser.id);
      //     break;
      //   case 1:
      //     this.sendMessageApi(
      //       JSON.stringify(content),
      //       format,
      //       this.selectedUser.id
      //     );
      //     break;
      //   case 3:
      //     this.sendMessageApi(
      //       JSON.stringify(content),
      //       format,
      //       this.selectedUser.id
      //     );
      //     break;
      //   case 8:
      //     this.sendMessageApi(
      //       JSON.stringify(content),
      //       format,
      //       this.selectedUser.id
      //     );
      //     break;
      //   default:
      //     break;
      // }
      this.inputMessage = "";
    },
    /**
     * send the message
     */
    sendMessageApi(
      content,
      format,
      receiver,
      resend = false,
      resendData = null
    ) {
      let that = this;
      if (content === "" || content.length <= 1 || content.trim() == '') {
        this.emptyMessageError = true;
        return;
      }

      // let data = {
      //   content: content,
      //   action: that.channelButtonGroup ? "1" : "0",
      //   sender: that.$store.state.login_account.account,
      //   receiver: receiver,
      //   format: format,
      //   sending: true,
      // };

      // let tempId = Date.now();
      // if (!resend) {
      //   data.id = tempId;
      //   data.read = true;
      //   data.timestamp = tempId;
      //   that.$store.commit("setMessage", {
      //     message: data,
      //     caller: that,
      //     sentMessage: true,
      //   });
      // } else {
      //   data.oldId = resendData.id;
      //   data.sending = true;
      //   that.$store.commit("updateMessage", {
      //     message: data,
      //     caller: that,
      //     sentMessage: true,
      //   });
      // }

      // setTimeout(() => {
      $(".chatWindowBody").animate(
        { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
        500
      );
      // }, 500);
      // data.id = null;

      const messageData = {
        message: content.trim(),
        receiver: receiver,
        sender: this.$store.state.number,
        type: format,
        user_type: 0,
        sender_name: this.$store.state.loginUserName,
        receiver_name: this.selectedUser.name
      }

      that
        .$http({
          url: `${that.$baseURL}/app/Chat/send`,
          method: "post",
          headers: {
            "Authorization": that.$store.state.token,
          },
          data: messageData,
        })
        .then((res) => {
          if (res.data.status == 200) {
            that.selectedMessageList.push(res.data.data);
            that.$store.commit('switchPositions', receiver);

            setTimeout(() => {
              that.checkStatusOfMsgs()
            }, 2000)
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }
          // if (res.data.code == 200) {
          //   data.timestamp = res.data.timestamp;
          //   data.id = res.data.id;
          //   data.read = true;

          //   if (!resend) {
          //     // that.$store.commit("setMessage", {
          //     //   message: data,
          //     //   caller: that,
          //     //   sentMessage: true,
          //     // });
          //     data.oldId = tempId; // resendData.id;
          //     data.sending = false;
          //     that.$store.commit("updateMessage", {
          //       message: data,
          //       caller: that,
          //       sentMessage: true,
          //     });
          //   } else {
          //     data.oldId = resendData.id;
          //     data.sending = false;
          //     that.$store.commit("updateMessage", {
          //       message: data,
          //       caller: that,
          //       sentMessage: true,
          //     });
          //   }

          //   // $(".chatWindowBody").animate(
          //   //   { scrollTop: $(".chatWindowBodyUl").height() },
          //   //   "fast"
          //   // );
          //   // console.log($(".chatWindowBody").height());
          //   // $('.chatWindowBody').animate({scrollTop: $('#bottomChat').offset().top}, "slow");
          // }
        })
        .catch((res) => {
          console.log("error sending message");
          if (!resend) {
            data.timestamp = new Date().getTime();
            data.id = "404" + new Date().getTime();
            data.read = true;
            data.sendfail = true;
            data.sending = false;
            (data.action = that.channelButtonGroup ? "1" : "0"),
              that.$store.commit("setMessage", {
                message: data,
                caller: that,
                sentMessage: true,
              });
          } else {
            data.sending = false;
            data.sendfail = true;
            that.$store.commit("updateMessage", {
              message: data,
              caller: that,
              sentMessage: true,
            });
          }
        })
        .finally(() => {
          // setTimeout(() => {
          //   $(".chatWindowBody").animate(
          //     { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
          //     500
          //   );
          // }, 500);
        });
    },
    resendFailMessage(message) {
      this.sendMessageApi(
        message.content,
        message.format,
        message.receiver,
        true,
        message
      );
    },
    showChannelMembers() {
      this.$refs.showChannelMembers.loadChannelMembers();
    },

    showMembersList() {
      this.$refs.showMembersList.showMembersList();
    },

    getHistoryMsg() {
      this.$refs.getHistoryMsg.loadHistory();
    },

    getHistoryChannel() {
      this.$refs.getHistoryChannel.loadHistoryChannel();
    },

    appendQuestion(msg) {
      this.inputMessage = msg;
    },
    processContent(content) {
      let that = this;
      content = content.replace(/face\[([^\s[\]]+?)\]/g, function (face) {
        //转义表情
        var alt = face.replace(/^face/g, "");
        var imgPath = that.emojiIcons.find((o) => alt === o.key);
        return (
          '<img alt="' +
          alt +
          '" title="' +
          alt +
          '" src="' +
          require(`../assets/img/emoji_files/${imgPath.id}.png`) +
          '">'
        );
      });
      content = content.replace(/https?:\/\/[^\s]+/, (link) => {
        return '<a href='+link+' target=”_blank”>'+link+'</a>'
      })
      return content;
    },
    onEmoji(emoji) {
      this.showPopoverEmoji = false;
      this.inputMessage += "face" + emoji.key;
      this.onCloseEmojiPop();
    },
    onImageUpload(e) {
      this.uploadFile(e.target.files[0], 3);
    },
    onVideoUpload(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.uploadApiFile(formData, 8);
    },
    onFileUpload(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.uploadApiFile(formData, 3);
    },
    uploadFile(file, format) {
      let that = this;
      let formData = new FormData();
      formData.append("file", file);
      // Toast.loading();
      this.isShowLoader = true;
      that
        .$http({
          url: `${that.$baseURL}/app/Chat/Upload/upload`,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((res) => {
          this.isShowLoader = false;
          if (res.data.status == 200) {
            const uploadURL = res.data.data;
            // console.log(format, uploadURL);
            that.sendMessage(format, uploadURL);
          }
          // var data = res.data;
          // if (data.code == 200) {
          //   that.sendMessage(1, data.data);
          // } else {
          //   this.isShowLoader = false;
          // }
        }).catch((e) => {
          console.log(e.message);
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    // uploadApiFile(formData, format) {
    //   let that = this;
    //   this.isShowLoader = true;
    //   that
    //     .$http({
    //       url: "/api/file/file",
    //       method: "post",
    //       headers: {
    //         "access-token": that.$store.state.token,
    //         "Content-Type": "multipart/form-data",
    //       },
    //       data: formData,
    //     })
    //     .then((res) => {
    //       this.isShowLoader = false;
    //       var data = res.data;
    //       var content = {};
    //       if (data.code == 200) {
    //         switch (format) {
    //           case 8:
    //             content = {
    //               duration: 2,
    //               image: "73859a7c9b404f16b4c818b0d2983a9c.jpg",
    //               mode: 1,
    //               size: "5",
    //               video: data.data.file,
    //             };
    //             that.sendMessage(8, content);
    //             break;
    //           case 3:
    //             content = {
    //               file: data.data.file,
    //               name: data.data.original,
    //               size: "24280",
    //             };
    //             that.sendMessage(3, content);
    //             break;
    //           default:
    //             break;
    //         }
    //       } else {
    //         this.isShowLoader = false;
    //       }
    //     })
    //     .finally(() => {
    //       this.isShowLoader = false;
    //     });
    // },
    loadOnlineUsers() {
      let that = this;
      that
        .$http({
          url: "api/user/online",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          var data = res.data.data;
          that.$store.commit("updateUserList", data.agentList);
        });
    },
    onFile(content) {
      // let url = this.$baseStoragePath + JSON.parse(content).file;
      window.open(content);
    },
    loadSystemMessages() {
      let that = this;
      that
        .$http({
          url: "api/message/getMessageHistory/" + 0 + "/system",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          that.listLoading = false;
          let data = res.data;
          if (data.data.length > 0) {
            that.$store.commit("addMessageToSystem", data.data);
          }
          that.$store.commit("readSystemMessages");
        });
    },
    onSearchButton() {
      eventBus.$emit("onShowSearchPop");
    },
    onSearchButtonChannel() {
      eventBus.$emit("onShowSearchPopChannel");
    },
    onSelectedUser(data) {
      //search for the user in the recent list
      //if in the list load the chat
      //if not in the list add to the list and open the chat
      let account = this.$store.state.message_list.find(
        (o) => o.id === data.account
      );
      if (!account) {
        this.$store.commit("updateUserList", [data]);
        this.onSelectedUser(data);
        return;
      }
      account.timestamp = Date.now();
      this.$store.state.message_list = this.$store.state.message_list
        .slice()
        .sort((a, b) => b.timestamp - a.timestamp);
      this.onRecentUser(account);
    },
    onSelectedChannel(data) {
      //search for the user in the recent list
      //if in the list load the chat
      //if not in the list add to the list and open the chat
      let account = this.$store.state.channel_list.find(
        (o) => o.id === data.id
      );
      if (!account) {
        this.$store.commit("updateChannelList", [data]);
        this.onSelectedUser(data);
        return;
      }
      account.timestamp = Date.now();
      this.$store.state.channel_list = this.$store.state.channel_list
        .slice()
        .sort((a, b) => b.timestamp - a.timestamp);
      this.onRecentChannel(account);
    },
    // ============== show channel and agent list ======================
    showChannelList() {
      this.channelListPannel = true;
      this.agentListPannel = false;

      // this.channelButtonGroup = true;
      // this.agentButtonGroup = false;
    },

    showAgentList() {
      this.agentListPannel = true;
      this.channelListPannel = false;

      // this.agentButtonGroup = true;
      // this.channelButtonGroup = false;
    },
    playSound() {
      var audio = new Audio(noticeSound);
      audio.play().catch((e) => {
        // console.log("Error");
      });
    },
    //============================== load all channel list ================
    loadAllChannel() {
      let that = this;
      that
        .$http({
          url: "/api/group/list?type=1",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            // that.allChannelList = res.data.dataList;
            that.$store.commit("updateChannelList", res.data.dataList);
          }
        });
    },
    //============================== click on one channel ==============
    onRecentChannel(item) {
      // this.selectedChannel.channelname = item.name;
      // this.selectedChannel.channelid = item.id;

      //show channel button group in the top
      this.channelButtonGroup = true;
      this.agentButtonGroup = false;

      this.selectedUser = item.user;
      this.selectedMessageList = item.messages;


      item.unread = 0;
      //set unread messages read
      this.$store.commit("readAllUnreadChannel", {
        account: this.selectedUser.account,
        context: this,
      });
      setTimeout(() => {
        $(".chatWindowBody").animate(
          { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
          500
        );
      }, 500);
    },
    /**
     * get information about who sent the message
     *
     * 1. get message sender information from api
     * 2. store in storage
     * 3. next tiime before getting from api check storage if not found get from api
     */
    getMessageSenderInformation(sender) {
      let that = this;

      var user = that.$store.state.member_cache.find((o) => o.id === sender);
      if (user) {
        return user.username;
      }

      that
        .$http({
          url: "api/user/getUserUpdated?account=" + sender,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            var data = res.data.data[sender];
            that.$store.commit("addMemberCache", data);
            return data.username;
          }
          return sender;
        })
        .catch(() => {
          return sender;
        });
    },
    onPaste(pasteEvent, callback) {
      var items = pasteEvent.clipboardData.files;

      if (items.length > 0) {
        if (items[0].type === "image/png") {
          this.uploadFile(items[0], 1);
        } else if (items[0].type === "image/jpeg") {
          this.uploadFile(items[0], 1);
        } else {
          let formData = new FormData();
          formData.append("file", items[0]);
          // this.uploadApiFile(formData, 3);
          this.uploadFile(items[0], 3);
        }
      }
    },
    getRecentUserList() {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/CustomerAgent/online",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: that.$store.state.number
          }
        }).then(res => {
          if (res.data.status == 200) {
            let users = res.data.data;
            let newUsers = [];
            const storedAgents = that.$store.state.message_list;

            if (users.length > 0) {
              users.forEach(user => {
                const userInAgent = storedAgents.find(item => item.number == user.number)

                // If user is not in the current list
                if (!userInAgent) {
                  const isUserAdded = newUsers.find(item => item.number == user.number)
                  // Add user to the array only if user not already in the array
                  if (!isUserAdded) {
                    newUsers.push(user);
                  }
                } else {
                  newUsers.push(userInAgent)
                }
              })

              // Push the agents from agentlist array to the new array
              storedAgents.forEach(storeAgent => {
                const isInNewUsers = newUsers.find(item => item.number == storeAgent.number)
                console.log(isInNewUsers);
                if (!isInNewUsers) {
                  newUsers.push(storeAgent)
                }
              })
            } else {
              newUsers = storedAgents
            }
            
            // const newTotalUsers = [...newUsers, ...that.$store.state.message_list]
            this.$store.commit("clearAgentList");
            this.$store.commit("setAgentList", newUsers)

            this.getUserHistory()
          }
        })
    },
    getAgentList() {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/agentList",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {},
        })
        .then((res) => {
          if (res.data.status == 200) {
            let data = res.data;
            let loggedUserDetails = data.data.filter((e) => { return e.number === that.$store.state.number })
            this.$store.commit("setLoggedUserName", loggedUserDetails[0].name)

            if (this.$store.state.message_list.length > 0) {
              const agents = [];
              data.data.map((apiAgent) => {
                const agentAvailable = this.$store.state.message_list.find(availableAgent => availableAgent.number == apiAgent.number);
                if (agentAvailable == undefined) {
                  agents.push(apiAgent);
                }
              });

              let allAgents = [...agents, ...this.$store.state.message_list]
              //remove own agent and unnecessary values
              allAgents = allAgents.filter(function (element) {
                return element !== undefined && element.number != that.$store.state.number;
              });

              this.$store.commit("clearAgentList");

              //Remove agents that were disabled by the admin
              allAgents.forEach(item => {
                const availableInAPIList = data.data.find(agent => agent.number ===
                  item.number);
                if (!availableInAPIList) {
                  const removalIndex = allAgents.findIndex((element) => element.number == item.number);
                  allAgents.splice(removalIndex, 1)
                }
              })


              this.$store.commit("setAgentList", allAgents)
            } else {
              //remove our user from list
              const filtered = data.data.filter((agent) => agent.number != that.$store.state.number)

              this.$store.commit("setAgentList", filtered)
              //  this.$store.commit("setAgentList", data.data)
            }
            this.getRecentUserList()
            // this.getUserHistory()
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }
        }).catch((e) => {
          console.error(e.message);
        });
    },
    removeOwnAgent() {

    },
    getUserHistory(initialLoad = true) {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/getHistory",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: that.$store.state.number
          }
          ,
        })
        .then((res) => {
          if (res.data.status == 200) {
            let data = res.data;
            data.data.forEach((element) => {
              this.$store.state.message_list.forEach((e) => {
                if (e.number === element.receiver || e.number === element.sender) {
                  const messagePresent = e.agentMessageList.find(message => message.order_id == element.order_id);
                  if (!messagePresent) {
                    e.agentMessageList.push(element)
                  }
                }
              })
              if (element.sender == "SYSTEM") {
                this.$store.commit('addNewSystemMessage', element);
              }
            })
            // let lastUpdatedMsg = data.data[data.data.length - 1]
            if (initialLoad) {
              const reversedData = data.data.reverse();
              if (reversedData.length) {
                reversedData.every(item => {

                  const ifFirstLogin = that.$store.state.lastOrderId == '';

                  let isSystemValidation = true;

                  if (ifFirstLogin) {
                    isSystemValidation = item.receiver != 'SYSTEM';
                  } else {
                    isSystemValidation = true;
                  }

                  if (item.is_read == 1 && isSystemValidation) {

                    this.$store.commit("setLastUpdatedOrderId", item.order_id)
                    this.$store.commit("setLastReceiver", item.receiver)

                    return false;
                  } else {
                    this.$store.commit("setLastUpdatedOrderId", '')
                    this.$store.commit("setLastReceiver", '')
                    return true;
                  }
                })
              } else {
                this.$store.commit("setLastUpdatedOrderId", '')
                this.$store.commit("setLastReceiver", '')
              }
            }

            // this.$store.commit("setLastUpdatedOrderId", lastUpdatedMsg.order_id)
            // this.$store.commit("setLastReceiver", lastUpdatedMsg.receiver)
            if (initialLoad) {
              this.getNewlyMsg();
              this.getOfflineMsg();
              setInterval(() => {
                this.getNewlyMsg();
              }, 3000);
              setInterval(() => {
                this.getOfflineMsg();
              }, 60000);
            }
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }

        }).catch((e) => {
          console.error(e.message);
        });
    },
    makeToast(variant = null, message = '') {
      this.$bvToast.toast(message, {
        title: `Variant ${variant || 'default'}`,
        variant: variant,
        solid: true
      })
    },
    getOfflineMsg() {
      let that = this;
      //Recieve new message API
      that
        .$http({
          url: that.$baseURL + "app/Chat/getOfflineMsg",
          method: "post",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': that.$store.state.token
          },
          data: {
            user_id: that.$store.state.number,
            receiver: that.$store.state.lastReceiver,
            order_id: that.$store.state.lastOrderId
          }
          ,
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.handleSystemMessage(res.data.data.systemMessages);
            data.data.msgList.map((element) => {
              const availableSender = this.$store.state.message_list.find(agent => agent.number == element.sender);

              const messageData = {
                count: null,
                create_time: element.create_time,
                id: Date.now(),
                is_read: element.is_read,
                message: element.message,
                msgId: element.msgId,
                order_id: element.order_id,
                receiver: element.receiver,
                sender: element.sender,
                time: element.time,
                type: element.type,
                user_id: element.user_id
              }


              that.playSound();
              //check if the current opened chat is same as the new message user 
              //if so just add to the list and show on UI
              //otherwise add to the store
              if (availableSender == undefined) {
                //new user
                this.$store.commit('addNewAgent', {
                  id: Date.now(),
                  agentMessageList: [],
                  count: 0,
                  name: element.sender_name,
                  number: element.sender,
                  account:element.sender_account
                })
                that.getUserHistory(false);
                const agent = that.$store.state.message_list.findIndex(agent => agent.number == element.sender);
                this.$store.commit('updateUnreadCount', {
                  index: agent,
                  reset: false
                  // count: allUnreadCount[property]
                })

                this.$store.commit('setLastUpdatedOrderId', element.order_id);
                this.$store.commit("setLastReceiver", element.receiver)

              } else {
                //existing user
                //Add new message to the list
                this.$store.commit('addNewMessageByAgentNumber', {
                  number: element.sender,
                  message: messageData
                })
                //Update last message
                this.$store.commit('setLastUpdatedOrderId', element.order_id);
                this.$store.commit("setLastReceiver", element.receiver)
                const agent = that.$store.state.message_list.findIndex(agent => agent.number == element.sender);
                this.$store.commit('updateAgentName', {
                  index: agent,
                  username: element.sender_name
                })

                if (this.selectedUser.number == element.sender) {
                  //Chat currently opened

                  this.getReadMsgs(this.selectedUser.number);

                } else {
                  //Chat currently closed
                  const allUnreadCount = data.data.count;
                  for (const property in allUnreadCount) {
                    if (agent != -1) {
                      this.$store.commit('updateUnreadCount', {
                        index: agent,
                        reset: false
                        // count: allUnreadCount[property]
                      })
                    }
                  }

                  // console.log(agent);
                }

              }
              setTimeout(() => {
                $(".chatWindowBody").animate(
                  { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
                  500
                );
              }, 500);
            });
          } else if (data.status == 401) {
            this.$router.push({ name: "Login" });
            // this.makeToast('danger', 'Messages API failed');
          } else {
            console.error("Message getting API failed...");
          }
        }).catch((e) => {
          console.error(e.message);
        });
    },
    getNewlyMsg() {
      let that = this;
      //Recieve new message API
      that
        .$http({
          url: that.$baseURL + "app/Chat/get",
          method: "post",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': that.$store.state.token
          },
          data: {
            user_id: that.$store.state.number,
            receiver: that.$store.state.lastReceiver,
            order_id: that.$store.state.lastOrderId
          }
          ,
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.handleSystemMessage(res.data.data.systemMessages);
            data.data.msgList.map((element) => {
              const availableSender = this.$store.state.message_list.find(agent => agent.number == element.sender);

              const messageData = {
                count: null,
                create_time: element.create_time,
                id: Date.now(),
                is_read: element.is_read,
                message: element.message,
                msgId: element.msgId,
                order_id: element.order_id,
                receiver: element.receiver,
                sender: element.sender,
                time: element.time,
                type: element.type,
                user_id: element.user_id,
                sender_account: element.sender_account,
                status: 1
              }


              that.playSound();
              //check if the current opened chat is same as the new message user 
              //if so just add to the list and show on UI
              //otherwise add to the store
              if (availableSender == undefined) {
                //new user
                this.$store.commit('addNewAgent', {
                  id: Date.now(),
                  agentMessageList: [],
                  count: 0,
                  name: element.sender_name,
                  real_name: element.sender_name,
                  number: element.sender,
                  account:element.sender_account
                })
                that.getUserHistory(false);
                const agent = that.$store.state.message_list.findIndex(agent => agent.number == element.sender);
                this.$store.commit('updateUnreadCount', {
                  index: agent,
                  reset: false
                  // count: allUnreadCount[property]
                })

                this.$store.commit('setLastUpdatedOrderId', element.order_id);
                this.$store.commit("setLastReceiver", element.receiver)

              } else {
                //existing user
                //Add new message to the list
                this.$store.commit('addNewMessageByAgentNumber', {
                  number: element.sender,
                  message: messageData
                })
                //Update last message
                this.$store.commit('setLastUpdatedOrderId', element.order_id);
                this.$store.commit("setLastReceiver", element.receiver)
                const agent = that.$store.state.message_list.findIndex(agent => agent.number == element.sender);
                this.$store.commit('updateAgentName', {
                  index: agent,
                  username: element.sender_name ? element.sender_name : availableSender.name
                })

                if (this.selectedUser.number == element.sender) {
                  //Chat currently opened

                  this.getReadMsgs(this.selectedUser.number);

                } else {
                  //Chat currently closed
                  const allUnreadCount = data.data.count;
                  for (const property in allUnreadCount) {
                    // const agent = that.$store.state.message_list.findIndex(agent=>agent.number == property);
                    if (agent != -1) {
                      this.$store.commit('updateUnreadCount', {
                        index: agent,
                        reset: false
                        // count: allUnreadCount[property]
                      })
                    }
                  }

                  // console.log(agent);
                }

              }
              setTimeout(() => {
                $(".chatWindowBody").animate(
                  { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
                  500
                );
              }, 500);
            });
            if (data.data.msgList.length) {
              this.checkStatusOfMsgs()
            }
            
            this.getMsgCountDisplay(data.data.count)
          } else if (data.status == 401) {
            this.$router.push({ name: "Login" });
            // this.makeToast('danger', 'Messages API failed');
          } else {
          }
        }).catch((e) => {
          console.error(e.message);
        });
    },
    handleSystemMessage(systemMessage) {
      if (systemMessage.msgList.length > 0) {
        this.playSound();

        systemMessage.msgList.forEach((message) => {
          //chat currently opened
          this.$store.commit('addNewSystemMessage', message);
          this.$store.commit('setLastUpdatedOrderId', message.order_id);
          this.$store.commit("setLastReceiver", message.receiver)
          if (this.selectedUser.sender == 'SYSTEM') {
            //Add to store 
            //should update since its assigned

          } else {
            //Increase notification count
            this.$store.commit('updateSystemMessageCount', false);
          }
          //Update last message ID and reciever
          //Add to system message array
          //Increase notification count if not in chat
          //If inside chat push to chat list 
        })
      }
    },
    getReadMsgs(senderId) {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/readMsg",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: that.$store.state.number,
            sender: senderId
          }
        })
        .then((res) => {
          if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }
        }).catch((e) => {
          console.error(e.message);
        })
    },
    getActiveName(selectedUser) {
      if (selectedUser.username == "") {
        // Initial case without selecting a chat
        return ""
      } else {
        if (selectedUser.status) {
          return selectedUser.name ? selectedUser.name : selectedUser.account
        } else if (!selectedUser.real_name) {
          return selectedUser.account ? selectedUser.account : selectedUser.number
        }
        return `${selectedUser.account ? selectedUser.account : selectedUser.number}-${selectedUser.real_name}`
      }
    },
    getAvatar(user, isCurrentUser) {
      if (isCurrentUser) {
        return this.avatorImg;
      }
      return user.status ? this.avatorImg : this.userAvatarImg;
    },
    checkStatusOfMsgs() {
      const unReadMsgs = this.selectedMessageList.filter(item => this.msgStatuses.get(item.order_id) != 1)
      const orderIds = unReadMsgs.map(item => item.order_id)

      if (orderIds.length) {
        let orderIdsAsString = ""
        orderIds.forEach(orderId => {
          if (orderIdsAsString === "") {
            orderIdsAsString += orderId
          } else {
            orderIdsAsString += `,${orderId}`
          }
        })
        let that = this;
        that
          .$http({
            url: that.$baseURL + "/app/Chat/CustomerAgent/statusChecking",
            method: "post",
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              orderId: orderIdsAsString
            }
          })
          .then((res) => {
            let statuses = new Map(that.msgStatuses)
            if (res.status == 200) {
              const msgs = res.data;
              msgs.forEach(msg => {
                statuses.set(msg.order_id, msg.isRead)
              })
              that.msgStatuses = statuses
            }
          }).catch((e) => {
            console.error(e.message);
          })
      }
    },
    getMessageStatus(msg) {
      return msg.sender == this.$store.state.number ? this.msgStatuses.get(msg.order_id) : null
    },
    deleteMsg(messageOrder, menuId) {
      this.$refs[menuId][0].close()
      this.openedContextId = ''

      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/CustomerAgent/deleteMessage",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            orderId: messageOrder
          }
        })
        .then((res) => {
          if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          } else {
            that.$store.commit("markMsgAsDeleted", { agentNumber: this.selectedUser.number, messageOrderId: messageOrder })

            const removedLocal = that.selectedMessageList.findIndex(item => item.order_id == messageOrder)
            that.selectedMessageList[removedLocal].status = 0;
          }
        }).catch((e) => {
          console.error(e.message);
        })
    },
    // getCustomersMSgCount(data) {
    //   const user = this.testStoreMsgs.find((e) => e.account == data.account)
    //   return user.count;
    // },
    getMsgCountDisplay(obj){
      if(Object.keys(obj).length !== 0) {
        this.$store.state.message_list.forEach((e) =>{
          Object.keys(obj).forEach((el) =>{
            if(e.id ==  el){
            this.$set(e, 'newcount', obj[Object.keys(obj)])
            this.$store.commit("setAgentList", e)
          }
          })
        })
      }
    }
  },
  mounted: function () {
    this.getAgentList()
    
    eventBus.$on("openSearchChat", (chat) => {
      this.onRecentUser(chat)
    })
  },
};
</script>

<style scoped>
/* .mainContainer {
  background: url(https://chatapp.hk9999z.com/resource/image/pattern.png) repeat,
    linear-gradient(#e1e4e7, #f3f4f5);
} */

.ownerName {
  padding: 10px 0px;
  height: 78px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.leftPanelStyle {
  height: 100%;
  background: #2c3e50;
}

.profileImage {
  width: 60px;
  padding: 3px;
  height: 60px;
  float: left;
  border: 2px solid #2ecc71;
}

.profileName {
  text-align: left;
  padding-top: 17px;
  margin: 0;
  color: #ffffff;
  font-size: 14px;
}

.profileNameList {
  text-align: left;
  padding-top: 17px;
  margin: 0;
  color: #ffffff;
  /* padding-left: 20px; */
}

.UserDetail {
  text-align: left;
  margin: 0;
  color: #424344;
  font-size: 13px;
}

.userInfoIcon {
  margin-top: 20px;
  color: #f8f9fa;
  width: 20px;
  height: 20px;
}

.copyButton {
  float: right;
  padding: 0px 7px;
  font-size: 11px;
}

.agentDetail {
  color: #2c3e50;
  font-size: 16px;
}

.container1 {
  height: auto;
  min-height: calc(100% - 188px);
  max-height: calc(100% - 188px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 40px;
}

.container1::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.messageCount {
  padding-top: 10px;
  float: right;
}

.bottomBar {
  height: 53px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #2c3e50;
  border-top: 1px solid #666d73;
}

.bottomContent {
  /* padding-right: 0px; */
  border-right: 1px solid #666d73;
}

.groupName {
  padding-top: 17px;
  margin: 0;
  padding: 11px 0px;
  color: #ffffff;
  text-align: center;
  margin-top: 6px;
  cursor: pointer;
}

/* ============= chat styles ============ */

.chatWindowBody {
  height: auto;
  min-height: calc(100% - 104px);
  max-height: calc(100% - 104px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatWindowBody::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.chatWindowBody ul li {
  list-style: none;
}

.chatUsername {
  text-align: left;
  margin: 0;
  line-height: 50px;
  color: #ffffff;
  font-weight: bold;
}

.receive {
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  display: inline-flex;
}

.receive .receiveFileStyle {
  text-align: left;
}

.receive .receiveFileStyle .receiveFileName {
  text-align: left;
}

.imageleft {
  width: 30px;
  border-radius: 50%;
  float: left;
  margin: 6px 8px 0 0;
  height: 30px;
}

.talktext {
  display: inline-block;
  position: relative;
  min-width: 280px;
  width: auto;
  height: auto;
  background-color: #435f7a;
  padding: 10px 15px;
  border-radius: 20px;
  color: #ffffff;
}

.talktext p {
  margin: 0px;
  max-width: 600px;
  text-align: justify;
}

.sent {
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  display: inline-block;
  text-align: right;
}

.sent .textStyle {
  text-align: right;
  min-width: 250px;
}

.sent .fileName {
  text-align: right;
}

.imageright {
  width: 30px;
  border-radius: 50%;
  float: right;
  margin: 6px 0 0 8px;
  height: 30px;
}

.message_input {
  height: 53px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

.emojiDesign {
  background-color: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  margin: 0;
  padding: 7px 14px;
  height: 150px;
  width: 234px;
}

.emojiListDesign {
  cursor: pointer;
  float: left;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  list-style: none;
  padding: 5px;
}

.popoverEmoji .popover-body {
  padding: 0px;
}
</style>
<style>
.popover-body {
  padding: 0px;
}

.userDetailLine {
  font-size: 12px;
  color: gray;
}
.delete-btn {
  background-color: rgb(212, 155, 155);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  text-align: center;
}
</style>